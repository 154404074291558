<template>
  <div @dragover.prevent @drop.prevent>
    <div @drop="dragFile" class="area" @click="$refs.FileInput.click()">
      <div v-if="!file">Clique aqui ou solte o arquivo nessa área para selecionar...</div>
      <div v-if="file" class="file-name">{{ file.name }}</div>
      <div>
        <b-button
          class="buttons"
          v-if="file"
          variant="outline"
          @click="clearSelectedFile"
        >
          Cancelar
        </b-button>
        <b-button
          class="buttons"
          v-if="file"
          variant="primary"
          @click="uploadFile"
        >
          Enviar
        </b-button>
      </div>
    </div>
    <input
      ref="FileInput"
      type="file"
      style="display: none"
      accept=".zip,.rar,.7zip"
      @change="onFileSelect"
    />

    <b-progress
      class="progress"
      v-if="file && progress"
      :max="100"
      show-progress
      :animated="progress !== 100"
      :variant="!error ? 'info' : 'danger'"
    >
      <b-progress-bar :value="progress">
        <span><strong>{{ progress }}%</strong></span>
      </b-progress-bar>
    </b-progress>

  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      progress: null,
      error: false,
    }
  },
  methods: {
    onFileSelect(e) {
      this.progress = null;
      this.error = false;
      const file = e.target.files[0];
      this.file = file;
    },
    dragFile(e) {
      this.progress = null;
      this.error = false;
      const fileType = e.dataTransfer.files[0].type;
      if (['application/zip','application/rar','application/7zip'].includes(fileType)) {
        this.file = e.dataTransfer.files[0];
      } else {
        this.$toast.error('Formato de arquivo não suportado, formatos aceitos: (.zip .rar .7zip)');
      }
    },
    uploadFile(ev) {
      ev.stopPropagation();
      const clinic = JSON.parse(localStorage.getItem('clinic'));
      const dataForm = new FormData();
      dataForm.append(`file`, this.file);
      dataForm.append(`type`, this.file.type);
      dataForm.append(`fileName`, this.file.name);

      const isLoading = this.$loading.show();
      this.api.uploadClinicFile(clinic.id, dataForm, {
        onUploadProgress: event => {
          this.progress = Math.round((event.loaded * 100) / event.total);
        }
      })
      .then(() => {
        this.$toast.success('Arquivo enviado com sucesso');
        this.file = null;
        this.error = false;
      })
      .catch(() => {
        this.$toast.error('O tamanho do arquivo deve ter no máximo 200 MB');
        this.error = true;
      })
      .finally(() => { isLoading.hide(); });
    },
    clearSelectedFile(ev) {
      ev.stopPropagation();
      this.file = null;
    }
  },
}
</script>
<style lang="scss" scoped>
.area {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 300px;
  border: 1px dashed;
  border-radius: 5px;
  cursor: pointer;
}
.file-name {
  padding: 10px;
  color: var(--type-placeholder);
}
.buttons {
  margin: 10px;
}
.progress {
  margin-top: 30px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  color: red; */
}
</style>
